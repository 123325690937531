import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';



export default function StepperVert(pageTextColor: any, steps: any) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepsArr, setStepsArr] = React.useState(pageTextColor.buttonBackgroundColor.steps);
  const [hasDoneIt, setHasDoneIt] = React.useState(false as boolean)

  React.useEffect(() => {
    if (pageTextColor.buttonBackgroundColor.copied === true && !hasDoneIt) {
        setActiveStep(1)
        setHasDoneIt(true)
      }
  }, [activeStep])


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

//   console.log(pageTextColor);
//   console.log(pageTextColor.buttonBackgroundColor.steps)

  return (
    <Box sx={{ maxWidth: 600 }} style={{color: `${pageTextColor.buttonBackgroundColor.pageTextColor}`, overflow: "scroll"}}>
      <div style={{paddingBottom: "15px"}}>
        <span style={{fontSize: "25px"}}>{pageTextColor.buttonBackgroundColor.title}</span>
      </div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepsArr.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              style={{color: `${pageTextColor.pageTextColor}`}}
              optional={
                index === 332 ? (
                  <Typography variant="caption">on Desktop Web Browser</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                    {step.outlookPersonalButton ?
                    <div style={{paddingBottom: "5px", paddingTop: "5px"}}>
                        {step.outlookPersonalButton}
                    </div>
                    : '' }
                    {step.outlookWorkButton ?
                    <div style={{paddingBottom: "5px", paddingTop: "5px"}}>
                        {step.outlookWorkButton}
                    </div>
                    : '' }
                    {step.copyButton ? 
                    <div style={{paddingBottom: "5px", paddingTop: "5px"}}>
                        {step.copyButton}
                    </div>
                    : '' }
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    style={{backgroundColor: `${pageTextColor.buttonBackgroundColor.buttonBackgroundColor}`}}
                  >
                    {index === stepsArr.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                    style={{backgroundColor: `white`}}
                  >
                    <span style={{color: "black"}}>Back</span>
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === stepsArr.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All set! Remember that it can take some time for attributes in M365 to update. If things aren't working and everything looks right, return to it tomorrow or later in the day.</Typography>
          <Button style={{backgroundColor: `${pageTextColor.buttonBackgroundColor.buttonBackgroundColor}`}} onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Start Over
          </Button>
          <Button style={{backgroundColor: "white"}} onClick={() => window.open('https://portal.azure.com/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Overview','_blank')} sx={{ mt: 1, mr: 1 }}>
            <span style={{color: "black"}}>Open Azure AD</span>
          </Button>
          {/* <Button style={{backgroundColor: `${pageTextColor.buttonBackgroundColor.buttonBackgroundColor}`}} onClick={() => window.open('https://support.microsoft.com/en-us/office/create-and-add-an-email-signature-in-outlook-5ff9dcfd-d3f1-447b-b2e9-39f91b074ea3','_blank')} sx={{ mt: 1, mr: 1 }}>
            Open MS Support Article
          </Button> */}
        </Paper>
      )}
    </Box>
  );
}