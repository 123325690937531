// @ts-nocheck
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { CheckOutlined, FileCopyOutlined } from "@material-ui/icons";
// import { Select, SelectChangeEvent, Skeleton } from "@mui/material";
import React, { useRef } from "react";
import "./App.css";
import ReactDOMServer from "react-dom/server";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import Signature from "./Signature";
import InputMask from "react-input-mask";
import { IMaskInput, IMaskMixin } from 'react-imask';
// import { Preview } from "@mui/icons-material";

// import { ToastContainer, toast } from 'react-toastify';

import { Client, Databases, Account, ID, Query } from 'appwrite';
import Table from "./Table";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { HexColorPicker } from "react-colorful";

import List from '@mui/material/List';
import ListItem from "@mui/material/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

import StepModal from "./StepModal";
import UploadAndDisplayImage from "./UploadAndDisplayImage";

const client = new Client();
const account = new Account(client);
const databases = new Databases(client);

const esg_project_id = "64f3b89f06b3e6a9d37f"

client
    .setEndpoint('https://sm-b1.smorin.app/v1')
    .setProject(`${esg_project_id}`);

const esg_db1 = "64f3b8e51831e63a90d9"
const esg_c1 = "64f3b8f27e7a6bfdfce6"

const esg_field_vis = "64f3bb87151160e5737b"
const esg_field_vis_doc = "64f3be2171359f374dda"

const esg_defaults_collect = "64f3b98a98421a486f11"
const esg_defaults_doc = "64f3bd327b14ee475661"

const useStyles = makeStyles((theme: Theme) =>
  // Styles for the web app
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
      "& .label-root": {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      borderRadius: "5px",
    },
    centeredImage: {
      display: "block",
      // justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
      // marginTop: "1rem",
      width: "450px",

      // Media query for screens smaller than 600px
      '@media (max-width: 600px)': {
        maxWidth: "345px", // Adjust the max width for smaller screens
      },

      // Media query for screens smaller than 400px
      '@media (max-width: 300px)': {
        maxWidth: "300px", // Adjust the max width for even smaller screens
      },
    },

    centeredText: {
      textAlign: "center",
    },
    warningIconStyle: {
      textAlign: "center",
      color: "#FFDC00",
      verticalAlign: "middle",
    },
    box: {
      width: "100%",
    },
    inputLabel: {
      marginLeft: 10,
      marginTop: 3,
    },
    select: {
      width: 300,
      height: 50,
      marginLeft: 0.7,
    },
  })
);


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export interface PhotoSignaturePropsPreview {
  logoPreview: MutableRefObject<HTMLCanvasElement>;
  logo: string;
  fullName: string;
  company: string;
  title: string;
  address: string;
  phone: string;
  website: string;
  mobile: string;
  calendlyLink: string;
  textmask: string;
  totalCopies: number;
  facebookURL: string;
  googleURL: string;
  linkedInURL: string;
}

export interface PhotoSignatureProps {
  logo: string;
  fullName: string;
  company: string;
  title: string;
  address: string;
  phone: string;
  website: string;
  mobile: string;
  calendlyLink: string;
  facebookURL: string;
  googleURL: string;
  linkedInURL: string;
  textmask: string;
  totalCopies: number;
}

interface State extends PhotoSignatureProps {
  copiedRich: boolean;
  copiedHTML: boolean;
  copiedOrgHTML: boolean;
  copiedPowershellHTML: boolean;
}

const initialState: State = {
  logo: "",
  fullName: "",
  company: "",
  title: "",
  address: "",
  phone: "",
  mobile: "",
  website: "",
  calendlyLink: "",
  copiedRich: false,
  textmask: "",
  totalCopies: null,
  copiedHTML: false,
  copiedOrgHTML: false,
  copiedPowershellHTML: false,
  facebookURL: "",
  googleURL: "",
  linkedInURL: "",
};

function App() {
  const classes = useStyles();
  const [state, setState] = React.useState<State>(initialState);

  const [responseDB, setResponseDB] = React.useState([] as any);

  const [email, setEmail] = React.useState('' as string);
  const [pass, setPassword] = React.useState('' as string);

  const [totalNumCopies, setTotalNumCopies] = React.useState(0 as number);

  const [authenticated, setAuthenticated] = React.useState(false as boolean);
  const [sessionID, setSessionID] = React.useState('' as string);

  const [adminLoading, setAdminLoading] = React.useState(true as boolean);
  const [loadingFields, setLoadingFields] = React.useState(true as boolean);

  const [showError, setShowError] = React.useState(false as boolean);
  const [errorMessage, setErrorMessage] = React.useState('' as string);
  const [loginButtonDisabled, setloginButtonDisabled] = React.useState(true as boolean);

  const [companyDisabled, setCompanyDisabled] = React.useState(false as boolean);
  const [titleDisabled, setTitleDisabled] = React.useState(false as boolean);
  const [addressDisabled, setAddressDisabled] = React.useState(false as boolean);
  const [mobilePhoneDisabled, setMobilePhoneDisabled] = React.useState(false as boolean);
  const [officePhoneDisabled, setOfficePhoneDisabled] = React.useState(false as boolean);
  const [websiteDisabled, setWebsiteDisabled] = React.useState(false as boolean);
  const [calendlyDisabled, setCalendlyDisabled] = React.useState(false as boolean);

  const [switchLoading, setSwitchLoading] = React.useState(false as boolean);

  const [expanded, setExpanded] = React.useState<string | false>('');

  const [defaultCompany, setDefaultCompany] = React.useState('' as string);
  const [defaultTitle, setDefaultTitle] = React.useState('' as string);
  const [defaultAddress, setDefaultAddress] = React.useState('' as string);
  const [defaultPhone, setDefaultPhone] = React.useState('' as string);
  const [defaultMobile, setDefaultMobile] = React.useState('' as string);
  const [defaultWebsite, setDefaultWebsite] = React.useState('' as string);
  const [defaultCalendlyLink, setDefaultCalendlyLink] = React.useState('' as string);

  const [pageBackgroundColor, setPageBackgroundColor] = React.useState("" as string);
  const [pageTextColor, setPageTextColor] = React.useState("" as string);
  const [podBackgroundColor, setPodBackgroundColor] = React.useState('' as string);
  const [buttonBackgroundColor, setButtonBackgroundColor] = React.useState('' as string);

  const [defaultSaveSuccess, setDefaultSaveSuccess] = React.useState(false as boolean);
  const [defaultSaveLoading, setDefaultSaveLoading] = React.useState(false as boolean);
  const [brandingSaveSuccess, setBrandingSaveSuccess] = React.useState(false as boolean);
  const [brandingSaveLoading, setBrandingSaveLoading] = React.useState(false as boolean);
  const [socialSavingSuccess, setSocialSavingSuccess] = React.useState(false as boolean);
  const [socialSavingLoading, setSocialSavingLoading] = React.useState(false as boolean);

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [mainLoading, setMainLoading] = React.useState(true as boolean);
  const [usern, setUsern] = React.useState('' as string);

  const [pageTitle, setPageTitle] = React.useState('' as string);

  const [clientIP, setClientIP] = React.useState('' as string);

  const [richCopied, setRichCopied] = React.useState(false as boolean);

  const [poshSigName, setPoshSigName] = React.useState('' as string);

  const [imgUrl, setImgUrl] = React.useState('' as string);

  const [facebookURL, setFacebookURL] = React.useState('' as string);
  const [googleURL, setGoogleURL] = React.useState('' as string);
  const [linkedInURL, setLinkedInURL] = React.useState('' as string);

  const ref = useRef(null);
  const inputRef = useRef(null);
  const PhoneMask = "000-000-0000";

  const IMaskPhoneInput = IMaskMixin(({ ...props }) => {
    return <TextField InputLabelProps={{ shrink: true }} {...props} />;
  });

  React.useEffect(() => {
    isStateChanged(false)
  })

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleSocialSaveClick = () => {
    setSocialSavingLoading(true)
    const promise = databases.updateDocument(`${esg_db1}`, `${esg_defaults_collect}`, `${esg_defaults_doc}`, {
      facebookURL: `${facebookURL}`,
      googleURL: `${googleURL}`,
      linkedInURL: `${linkedInURL}`,
    });

    promise.then(function (response) {
        // console.log(response); // Success
        setSocialSavingSuccess(true)
        setSocialSavingLoading(false)
        setTimeout(() => setSocialSavingSuccess(false), 5000)
      }, function (error) {
        console.log(error); // Failure
        setSocialSavingSuccess(false)
        setSocialSavingLoading(false)
    });
  }

  const handleSaveClick = () => {
    setDefaultSaveLoading(true)
    const promise = databases.updateDocument(`${esg_db1}`, `${esg_defaults_collect}`, `${esg_defaults_doc}`, {
      defaultCompany: `${defaultCompany}`,
      defaultAddress: `${defaultAddress}`,
      defaultMobile: `${defaultMobile}`,
      defaultPhone: `${defaultPhone}`,
      defaultWebsite: `${defaultWebsite}`,
      defaultCalendlyLink: `${defaultCalendlyLink}`,
      pageTitle: `${pageTitle}`,
    });

    promise.then(function (response) {
        // console.log(response); // Success
        setState({...state, [state.company]: defaultCompany, [state.address]: defaultAddress, [state.phone]: defaultPhone, [state.website]: defaultWebsite, [state.calendlyLink]: defaultCalendlyLink});
        setDefaultSaveSuccess(true)
        setDefaultSaveLoading(false)
        setTimeout(() => setDefaultSaveSuccess(false), 5000)
      }, function (error) {
        console.log(error); // Failure
        setDefaultSaveSuccess(false)
        setDefaultSaveLoading(false)
    });
  }

  // React.useEffect(() => {
  //   defaultSaveSuccess && setTimeout(setDefaultSaveSuccess(false), 5000)
  // }, [defaultSaveSuccess])


  const handleColorSaveClick = () => {
    setBrandingSaveLoading(true)
    const promise = databases.updateDocument(`${esg_db1}`, `${esg_defaults_collect}`, `${esg_defaults_doc}`, {
      pageBackgroundColor: `${pageBackgroundColor}`,
      pageTextColor: `${pageTextColor}`,
      podBackgroundColor: `${podBackgroundColor}`,
      buttonBackgroundColor: `${buttonBackgroundColor}`
    });
    promise.then(function (response) {
      // console.log(response); // Success
      setBrandingSaveSuccess(true)
      setBrandingSaveLoading(false)
      setTimeout(() => setBrandingSaveSuccess(false), 5000)
    }, function (error) {
        console.log(error); // Failure
        setBrandingSaveSuccess(false)
        setBrandingSaveLoading(false)
    });
  }




  const handleChangeAccord =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const hasRequiredFields: boolean =
    !!state.logo && !!state.fullName && !!state.phone;

  React.useEffect(() => {
    // setAdminLoading(true)

    setState(initialState);
    isStateChanged(false);

    fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(data => setClientIP(data.ip));

    const promise = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
    promise.then(function (response) {
        // console.log(response.documents); // Success
        setResponseDB(response.documents)
        // setState({...state, ['totalCopies']: response.total});
        setTotalNumCopies(response.total)
    }, function (error) {
        console.log(error); // Failure
    });

    // get current field settings
    const promise2 = databases.getDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`);
    promise2.then(function (response) {
        console.log(response); // Success
        setCompanyDisabled(response.companyDisabled)
        setTitleDisabled(response.titleDisabled)
        setAddressDisabled(response.addressDisabled)
        setMobilePhoneDisabled(response.mobilePhoneDisabled)
        setOfficePhoneDisabled(response.officePhoneDisabled)
        setWebsiteDisabled(response.websiteDisabled)
        setCalendlyDisabled(response.calendlyDisabled)
        // setLoadingFields(false)
    }, function (error) {
        console.log(error); // Failure
        // setLoadingFields(false)
    });
    // setAdminLoading(false)
  }, []);

  React.useEffect(() => {
    const promise3 = databases.getDocument(`${esg_db1}`, `${esg_defaults_collect}`, `${esg_defaults_doc}`);
    promise3.then(function (response) {
        console.log(response); // Success
        setPageTitle(response.pageTitle)
        document.title = `${response.pageTitle ? response.pageTitle : "SignatureGenie"}`
        setDefaultCompany(response.defaultCompany)
        setDefaultAddress(response.defaultAddress)
        setDefaultMobile(response.defaultMobile)
        setDefaultPhone(response.defaultPhone)
        setDefaultWebsite(response.defaultWebsite)
        setDefaultCalendlyLink(response.defaultCalendlyLink)
        setPageBackgroundColor(response.pageBackgroundColor)
        setPageTextColor(response.pageTextColor)
        setPodBackgroundColor(response.podBackgroundColor)
        setButtonBackgroundColor(response.buttonBackgroundColor)
        setImgUrl(response.defaultImgUrl)
        setFacebookURL(response.facebookURL)
        setGoogleURL(response.googleURL)
        setLinkedInURL(response.linkedInURL)
        setState({...state, [state.logo]: response.defaultImgUrl !== '' ? response.defaultImgUrl : '', [state.company]: defaultCompany !== '' ? defaultCompany : state.company, [state.address]: defaultAddress, [state.phone]: defaultPhone, [state.website]: defaultWebsite, [state.calendlyLink]: defaultCalendlyLink});
        clearState()
        setLoadingFields(false)
    }, function (error) {
        console.log(error); // Failure
        setLoadingFields(false)
    });
    setMainLoading(false)
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    isStateChanged(true)
  };

  React.useEffect(() => {
    const checkSession = account.get();
    checkSession.then(function (response) {
      console.log(response)
      if (response.status === true) {
        setAuthenticated(true)
        setAdminLoading(false)
      }
    }, function (error) {
        console.log(error)
        setAdminLoading(false)
    });
  }, []);

  React.useEffect(() => {
    if (true) {
      document.getElementById('container-id').setAttribute('style', `background-color: ${pageBackgroundColor} !important`);
      document.getElementById('root').setAttribute('style', `background-color: ${pageBackgroundColor} !important`);
      document.getElementsByTagName('body')[1].setAttribute('style', `background-color: ${pageBackgroundColor} !important`);
      document.getElementsByTagName('body')[0].setAttribute('style', `background-color: ${pageBackgroundColor} !important`);
    }
  }, [pageBackgroundColor])

  React.useEffect(() => {
    // const stylesheet = document.styleSheets[0]
    // stylesheet.insertRule(`.MuiPaper-root { color: ${pageTextColor} !important;}`, 0);
    // let i = 0
    // const cols = document.getElementsByClassName('.MuiPaper-root');
    // for(i = 0; i < cols.length; i++) {
    //   document.getElementsByClassName('.MuiPaper-root')[i].setAttribute('style', `color: ${pageTextColor} !important`)
    // }
  }, [pageTextColor])

  function handleSetImgUrl(str) {
    setImgUrl(str);
 }

  //signature will not show in the preview until the first bit of data is added
  const showSignature = () => {
    let progress = 0;

    if (state.fullName) {
      return (
        <React.Fragment>
          <Signature
            logo={imgUrl}
            fullName={state.fullName}
            company={defaultCompany !== '' ? defaultCompany : state.company}
            title={state.title}
            address={defaultAddress !== '' ? defaultAddress : state.address}
            phone={defaultPhone !== '' ? defaultPhone : state.phone}
            mobile={state.mobile}
            website={defaultWebsite !== '' ? defaultWebsite : state.website}
            calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
            textmask={state.textmask}
            totalCopies={totalNumCopies}
            facebookURL={facebookURL ? facebookURL : "https://facebook.com"}
            googleURL={googleURL ? googleURL : "https://google.com"}
            linkedInURL={linkedInURL ? linkedInURL : "https://linkedin.com"}
          />
          <Button
            onClick={copyToClipboard}
            endIcon={state.copiedRich ? <CheckOutlined /> : <FileCopyOutlined />}
            style={{backgroundColor: `${buttonBackgroundColor}`}}
          >
            {state.copiedRich ? "Copied!" : "Rich Text"}
          </Button>
          <Button
            onClick={copyHTMLToClipboard}
            endIcon={state.copiedHTML ? <CheckOutlined /> : <FileCopyOutlined />}
            style={{backgroundColor: `${buttonBackgroundColor}`}}
          >
            {state.copiedHTML ? "Copied!" : "HTML Text"}
          </Button>
          <Button
            endIcon={<DownloadIcon />}
            onClick={downloadHtmlFile}
            style={{backgroundColor: `${buttonBackgroundColor}`}}
          >
            HTML File
          </Button>
          <br/>
          <br/>
          <StepModal 
            buttonBackgroundColor={buttonBackgroundColor}
            pageTextColor={pageTextColor}
            steps={richTextSteps}
            modalButtonText={'M365 Rich Text Guide'}
            copied={state.copiedRich}
            title={"Office M365 Steps - Rich Text "}
          />
          {!authenticated ? '' :
          <>
          <br/>
          <Accordion>
            <AccordionSummary>M365 Organization-Wide Options</AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={1}>
          <Grid item xs={6}>
            <div>
              <h3>Organization-Wide Powershell Command</h3>
              <p>You'll need to log into a M365 Exchange Online Powershell session as administrator with the appropriate permissions. After doing so, run this command to create a new dynamic organization-wide transport rule. Attributes are pulled from AAD/M365 user contact information, be sure to fill this out ahead of time. It can take hours for attribute changes to be reflected in testing.</p>
              <Button
                endIcon={state.copiedPowershellHTML ? <CheckOutlined /> : <FileCopyOutlined />}
                onClick={copyOrgPowershellToClipboard}
                style={{backgroundColor: `${buttonBackgroundColor}`}}
              >
                {state.copiedPowershellHTML ? 'Copied Command!' : "Powershell command"}
              </Button>
              <div style={{paddingTop: "10px"}}>
                <StepModal 
                  buttonBackgroundColor={buttonBackgroundColor}
                  pageTextColor={pageTextColor}
                  steps={richOrgPowershellSteps}
                  modalButtonText={'M365 Org-wide PoSh Guide'}
                  copied={state.copiedPowershellHTML}
                  title={"Office M365 Steps - Org-wide Powershell "}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <h3>Organization-Wide HTML Markup</h3>
              <p>You'll need to login to the M365 administrator web portal. Create a new transport rule in Exchange Admin Center with your desired conditions. Choose 'Apply disclaimer', edit the disclaimer text, pasting the clipboard copied HTML markup, and save to create dynamic organization-wide signature. Attributes are pulled from AAD/M365 user contact information, be sure to fill this out ahead of time. It can take hours for attribute changes to be reflected in testing.</p>
              <Button
                endIcon={state.copiedOrgHTML ? <CheckOutlined /> : <FileCopyOutlined />}
                onClick={copyOrgHTMLToClipboard}
                style={{backgroundColor: `${buttonBackgroundColor}`}}
              >
                {state.copiedOrgHTML ? 'Copied HTML!' : "Organization HTML"}
              </Button>
            </div>
          </Grid>
          </Grid>
            </AccordionDetails>
          </Accordion>

          </>
          }
        </React.Fragment>
      );
    }
    if (progress > 0) {
      return (
        <div className={classes.centeredText}>
          <CircularProgressWithLabel variant="determinate" value={progress} />
        </div>
      );
    } else {
      return (
        <>
          <div>Begin filling in your info to generate your signature.</div>
        </>
      );
    }
  };

  const handleAdminClick = () => {
    if (authenticated === true) {
      setAuthenticated(false)
      document.getElementById('admin-login')!.style.display = "none";
      document.getElementById('admin-btn')!.innerHTML = "Admin Login";
      setEmail('')
      setPassword('')

      const promise = account.deleteSessions();

      promise.then(function (response) {
          console.log(response); // Success
      }, function (error) {
          console.log(error); // Failure
      });
    } else {
      if (document.getElementById('admin-login')!.style.display === 'block') {
        document.getElementById('admin-login')!.style.display = "none";
        setPassword('')
        setloginButtonDisabled(true)
      } else {
        document.getElementById('admin-login')!.style.display = "block";
        document.getElementById('email-field')!.focus()
      }
    }
  }

  const handleLoginClick = () => {
    const promise = account.createEmailSession(
      `${email}`,
      `${pass}`
    );
    promise.then(function (response) {
      console.log(response);
      if (response.name) {
        console.log('login success')
        user_n = response.name
      }
        const promise = account.get();

        promise.then(function (response) {
            if (response.status === true) {
              setAuthenticated(true)
              setSessionID(response.$id)
              setUsern(response.name)
              setAdminLoading(false)
              document.getElementById('admin-login')!.style.display = "none";
              document.getElementById('admin-btn')!.innerHTML = "Logout";

              // console.log(response)
              // toast.success('Successfully logged in!', {
              //   position: "bottom-left",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "colored",
              //   });
            } else {
              setAuthenticated(false)
              setAdminLoading(false)
              setPassword('')
              setloginButtonDisabled(true)
            }
        }, function (error) {
            console.log(error);
            setAuthenticated(false)
            setAdminLoading(false)
            setPassword('')
            setloginButtonDisabled(true)
        });
    }, function (error) {
      console.log(error.message);
      setAuthenticated(false)
      setAdminLoading(false)
      setShowError(true)
      setErrorMessage(error.message.trim() === 'Invalid password: Password must be at least 8 characters' ? 'Invalid credentials. Please check the email and password.' : error.message)
      setPassword('')
      setloginButtonDisabled(true)
    });
  }

  const handleCompanySwitchChange = () => {
    if (companyDisabled) {
      setSwitchLoading(true)
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {companyDisabled: false});
      promise2.then(function (response) {
          setCompanyDisabled(false);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    } else {
      setSwitchLoading(true)
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {companyDisabled: true});
      promise2.then(function (response) {
          setCompanyDisabled(true);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    }
  }
  const handleTitleSwitchChange = () => {
    setSwitchLoading(true)
    if (titleDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {titleDisabled: false});
      promise2.then(function (response) {
          setTitleDisabled(false);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {titleDisabled: true});
      promise2.then(function (response) {
          setTitleDisabled(true);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    }
  }
  const handleAddressSwitchChange = () => {
    setSwitchLoading(true)
    if (addressDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {addressDisabled: false});
      promise2.then(function (response) {
          setAddressDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {addressDisabled: true});
      promise2.then(function (response) {
          setAddressDisabled(true);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    }
  }
  const handleMobilePhoneSwitchChange = () => {
    setSwitchLoading(true)
    if (mobilePhoneDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {mobilePhoneDisabled: false});
      promise2.then(function (response) {
          setMobilePhoneDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {mobilePhoneDisabled: true});
      promise2.then(function (response) {
          setMobilePhoneDisabled(true);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    }
  }
  const handleOfficePhoneSwitchChange = () => {
    setSwitchLoading(true)
    if (officePhoneDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {officePhoneDisabled: false});
      promise2.then(function (response) {
          setOfficePhoneDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {officePhoneDisabled: true});
      promise2.then(function (response) {
          setOfficePhoneDisabled(true);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    }
  }
  const handleWebsiteSwitchChange = () => {
    setSwitchLoading(true)
    if (websiteDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {websiteDisabled: false});
      promise2.then(function (response) {
          setWebsiteDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {websiteDisabled: true});
      promise2.then(function (response) {
          setWebsiteDisabled(true);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    }
  }
  const handleCalendlySwitchChange = () => {
    setSwitchLoading(true)
    if (calendlyDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {calendlyDisabled: false});
      promise2.then(function (response) {
          setCalendlyDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {calendlyDisabled: true});
      promise2.then(function (response) {
          setCalendlyDisabled(true);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    }
  }




const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: `${pageTextColor} !important`,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: `${buttonBackgroundColor}`,
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: `${pageTextColor} !important`,
      borderRadius: 20 / 2,
    },
  }));

  const copyHTMLToClipboard = () => {  
    const htmlSignature = ReactDOMServer.renderToStaticMarkup(
      <Signature
        logo={imgUrl}
        fullName={state.fullName}
        company={defaultCompany !== '' ? defaultCompany : state.company}
        title={state.title}
        address={defaultAddress !== '' ? defaultAddress : state.address}
        phone={defaultPhone !== '' ? defaultPhone : state.phone}
        mobile={state.mobile}
        website={defaultWebsite !== '' ? defaultWebsite : state.website}
        // facebookLink={state.facebookLink}
        calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
        textmask={state.textmask}
        totalCopies={totalNumCopies}
        facebookURL={facebookURL ? facebookURL : "https://facebook.com"}
        googleURL={googleURL ? googleURL : "https://google.com"}
        linkedInURL={linkedInURL ? linkedInURL : "https://linkedin.com"}
      />
    );

    const clipboardItem = new
    ClipboardItem({'text/html':  new Blob([htmlSignature],
                                          {type: 'text/html'}),
                   'text/plain': new Blob([htmlSignature],
                                          {type: 'text/plain'})});

    navigator.clipboard.write([clipboardItem])
     .then(() => {
       const promiseAccount = account.createAnonymousSession();
       promiseAccount.then(function () {
       }, function (error) {
         console.log(error); // Failure
       });
       const promise = databases.createDocument(`${esg_db1}`, `${esg_c1}`, ID.unique(), {
         name: `${state.fullName}`, 
         title: `${state.title}`, 
         company: `${defaultCompany ? defaultCompany : state.company}`,
         copyType: 'HTML Text',
         clientIP: `${clientIP}`
       });
       promise.then(function () {
         const promise2 = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
         promise2.then(function (response) {
            //  console.log(response.documents); // Success
             setResponseDB(response.documents)
             setTotalNumCopies(response.total)
         }, function (error) {
             console.log(error); // Failure
         });
       }, function (error) {
         console.log(error); // Failure
       });


       setState((prevState) => ({
         ...prevState,
         copiedHTML: true,
       }));
     })
     .catch(err => {
       setState((prevState) => ({
         ...prevState,
         copiedHTML: false,
       }));
       console.log('Error copying to clipboard! Try a different browser.')
     });
 }


//  const handlePhoneChange = (val) => {
//   setDefaultPhone(val)
//  }

 const copyOrgPowershellToClipboard = () => {
  const htmlSignature = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body>
        <Signature
          logo={imgUrl}
          fullName='%%FirstName%% %%LastName%%'
          company='%%Company%%'
          title='%%Title%%'
          address='%%StreetAddress%% %%City%%, %%StateOrProvince%% %%PostalCode%%'
          phone='%%Phone%%'
          mobile='%%MobilePhone%%'
          website={defaultWebsite ? defaultWebsite : state.website}
          calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
          textmask={state.textmask}
          totalCopies={totalNumCopies}
          facebookURL={facebookURL ? facebookURL : "https://facebook.com"}
          googleURL={googleURL ? googleURL : "https://google.com"}
          linkedInURL={linkedInURL ? linkedInURL : "https://linkedin.com"}
        />
      </body>
    </html>
  );

  const powershellCommand = "New-TransportRule -Name 'Org-Wide External Signature - SignatureGenie' -Enabled $true -SentToScope NotInOrganization -ApplyHtmlDisclaimerText '" + htmlSignature + "'"

  const clipboardItem = new
  ClipboardItem({'text/html':  new Blob([powershellCommand],
                                        {type: 'text/html'}),
                 'text/plain': new Blob([powershellCommand],
                                        {type: 'text/plain'})});

                                        

    navigator.clipboard.write([clipboardItem])
   .then(() => {
     const promiseAccount = account.createAnonymousSession();
     promiseAccount.then(function () {
     }, function (error) {
       console.log(error); // Failure
     });
     const promise = databases.createDocument(`${esg_db1}`, `${esg_c1}`, ID.unique(), {
       name: `${state.fullName}`, 
       title: `${state.title}`, 
       company: `${defaultCompany ? defaultCompany : state.company}`,
       copyType: 'Org HTML Text',
       clientIP: `${clientIP}`
     });
     promise.then(function () {
       const promise2 = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
       promise2.then(function (response) {
          //  console.log(response.documents); // Success
           setResponseDB(response.documents)
           setTotalNumCopies(response.total)
       }, function (error) {
           console.log(error); // Failure
       });
     }, function (error) {
       console.log(error); // Failure
     });


     setState((prevState) => ({
       ...prevState,
       copiedPowershellHTML: true,
     }));
   })
   .catch(err => {
     setState((prevState) => ({
       ...prevState,
       copiedPowershellHTML: false,
     }));
     console.log(err)
   });
 }


 const copyOrgHTMLToClipboard = () => {  
  const htmlSignature = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body>
        <Signature
          logo={imgUrl}
          fullName='%%FirstName%% %%LastName%%'
          company='%%Company%%'
          title='%%Title%%'
          address='%%StreetAddress%% %%City%%, %%StateOrProvince%% %%PostalCode%%'
          phone='%%Phone%%'
          mobile='%%MobilePhone%%'
          website={defaultWebsite ? defaultWebsite : state.website}
          calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
          textmask={state.textmask}
          totalCopies={totalNumCopies}
          facebookURL={facebookURL ? facebookURL : "https://facebook.com"}
          googleURL={googleURL ? googleURL : "https://google.com"}
          linkedInURL={linkedInURL ? linkedInURL : "https://linkedin.com"}
        />
      </body>
    </html>
  );

  const clipboardItem = new
  ClipboardItem({'text/html':  new Blob([htmlSignature],
                                        {type: 'text/html'}),
                 'text/plain': new Blob([htmlSignature],
                                        {type: 'text/plain'})});

  navigator.clipboard.write([clipboardItem])
   .then(() => {
     const promiseAccount = account.createAnonymousSession();
     promiseAccount.then(function () {
     }, function (error) {
       console.log(error); // Failure
     });
     const promise = databases.createDocument(`${esg_db1}`, `${esg_c1}`, ID.unique(), {
       name: `${state.fullName}`, 
       title: `${state.title}`, 
       company: `${defaultCompany ? defaultCompany : state.company}`,
       copyType: 'Org HTML Text',
       clientIP: `${clientIP}`
     });
     promise.then(function () {
       const promise2 = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
       promise2.then(function (response) {
          //  console.log(response.documents); // Success
           setResponseDB(response.documents)
           setTotalNumCopies(response.total)
       }, function (error) {
           console.log(error); // Failure
       });
     }, function (error) {
       console.log(error); // Failure
     });


     setState((prevState) => ({
       ...prevState,
       copiedOrgHTML: true,
     }));
   })
   .catch(err => {
     setState((prevState) => ({
       ...prevState,
       copiedOrgHTML: false,
     }));
     console.log(err)
   });
}



  const copyToClipboard = () => {
     const richTextDiv = document.getElementById("generated-signature") as HTMLDivElement;
     const clipboardItem = new ClipboardItem({
     	"text/plain": new Blob(
     		[richTextDiv.innerText],
     		{ type: "text/plain" }
     	),
     	"text/html": new Blob(
     		[richTextDiv.outerHTML],
     		{ type: "text/html" }
     	),
     });
   
     navigator.clipboard.write([clipboardItem])
      .then(() => {
        const promiseAccount = account.createAnonymousSession();
        promiseAccount.then(function () {
        }, function (error) {
          console.log(error); // Failure
        });
        const promise = databases.createDocument(`${esg_db1}`, `${esg_c1}`, ID.unique(), {
          name: `${state.fullName}`, 
          title: `${state.title}`, 
          company: `${defaultCompany ? defaultCompany : state.company}`,
          copyType: 'Rich Text',
          clientIP: `${clientIP}`
        });
        promise.then(function () {
          const promise2 = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
          promise2.then(function (response) {
              // console.log(response.documents); // Success
              setResponseDB(response.documents)
              setTotalNumCopies(response.total)
              setRichCopied(true)
              setRichCopied(setTimeout(false, 5000))
          }, function (error) {
              console.log(error); // Failure
              setRichCopied(false)
          });
        }, function (error) {
          console.log(error); // Failure
          setRichCopied(false)
        });

        setState((prevState) => ({
          ...prevState,
          copiedRich: true,
        }));
      })
      .catch(err => {
        setState((prevState) => ({
          ...prevState,
          copiedRich: false,
        }));
        console.log('Error copying to clipboard! Try a different browser.')
      });
  }

  function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
  
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }



  const downloadHtmlFile = () => {
    const htmlSignature = ReactDOMServer.renderToStaticMarkup(
      <Signature
        logo={imgUrl}
        fullName={state.fullName}
        company={defaultCompany !== '' ? defaultCompany : state.company}
        title={state.title}
        address={defaultAddress !== '' ? defaultAddress : state.address}
        phone={defaultPhone !== '' ? defaultPhone : state.phone}
        mobile={state.mobile}
        website={defaultWebsite !== '' ? defaultWebsite : state.website}
        calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
        textmask={state.textmask}
        totalCopies={totalNumCopies}
        facebookURL={facebookURL ? facebookURL : "https://facebook.com"}
        googleURL={googleURL ? googleURL : "https://google.com"}
        linkedInURL={linkedInURL ? linkedInURL : "https://linkedin.com"}
      />
    );
    const lowerCaseName = state.fullName.toLowerCase();
    const nameSplit = lowerCaseName.split(" ");
    const firstInitial = nameSplit[0].charAt(0);
    const lastName = nameSplit[1];
    const blob = new Blob([htmlSignature]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = fileDownloadUrl;
    link.setAttribute("download", `${firstInitial}${lastName}.htm`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const isStateChanged = () => {
    return JSON.stringify(state) === JSON.stringify(initialState);
  };

  const clearState = () => {
    setState(initialState);
  };


  const richOrgPowershellSteps = [
    {
      label: "Copy to clipboard!",
      description: `Copy the powershell command to your clipboard. If you didn't already, here it is:`,
      copyButton: 
        (<Button
          onClick={copyOrgPowershellToClipboard}
          endIcon={state.copiedPowershellHTML ? <CheckOutlined /> : <FileCopyOutlined />}
          style={{backgroundColor: `${buttonBackgroundColor}`}}
        >
          {state.copiedPowershellHTML ? "Copied!" : "Powershell Command"}
        </Button>)
    },
    {
      label: 'Connect to Exchange Online Powershell',
      description:
        "Use your member admin credentials to start an Exchange Online Powershell session. For information on Exchange Online Powershell Module:",
      outlookWorkButton: 
      (<Button 
        style={{backgroundColor: `${buttonBackgroundColor}`}}
        onClick={() => window.open('https://learn.microsoft.com/en-us/powershell/exchange/connect-to-exchange-online-powershell?view=exchange-ps','_blank')}
      >
        How to connect to Exchange Online
      </Button>),
    },
    {
      label: 'Paste & run the command',
      description: `Paste the command into your authenticated M365 Exchange Online Powershell session and hit Enter. You can copy it again below if you need to.`,
      outlookWorkButton: 
        <Button 
          endIcon={state.copiedPowershellHTML ? <CheckOutlined /> : <FileCopyOutlined />}
          style={{backgroundColor: `${buttonBackgroundColor}`}} 
          onClick={copyOrgPowershellToClipboard}
        >
          Powershell Command
        </Button>
    },
    {
      label: 'Save & Test',
      description: `When you're pleased, save the signature and send yourself or others some test messages to confirm it's working!`,
    },
  ];


  const richTextSteps = [
    {
      label: "Copy to clipboard!",
      description: `Copying the rich text to your clipboard should retain most of the formatting and styling when pasting into a rich text capable destination but we'll make sure.`,
      copyButton: 
        (<Button
          onClick={copyToClipboard}
          endIcon={state.copiedRich ? <CheckOutlined /> : <FileCopyOutlined />}
          style={{backgroundColor: `${buttonBackgroundColor}`}}
        >
          {state.copiedRich ? "Copied!" : "Rich Text"}
        </Button>)
    },
    {
      label: 'Log into Outlook and open settings',
      description:
        'Log into Outlook on the Web to access settings and create signatures that work across devices and platforms',
        description: `Log into Outlook on the Web, open settings in the top right corner (gear icon) and choose 'Compose & Reply' (or use the buttons below to go right there)`,
        outlookPersonalButton: <Button style={{backgroundColor: `${buttonBackgroundColor}`}} onClick={() => window.open('https://outlook.live.com/mail/0/options/mail/messageContent','_blank')}>M365 Personal Account</Button>,
        outlookWorkButton: <Button style={{backgroundColor: `${buttonBackgroundColor}`}} onClick={() => window.open('https://outlook.office.com/mail/options/mail/messageContent','_blank')}>M365 Work Account</Button>
    },
    {
      label: 'Give your signature a name',
      description: `In the name field, provide a few words to describe the signature.`,
    },
    {
      label: 'Paste your clipboard',
      description:
        'Paste the copied data from this site on your clipboard into the large text area/field below. You should see it almost exactly as it appears here. You may need to edit the font or other settings to dial it in to your liking.',
    },
    {
      label: 'Set as default',
      description:
        'Set your new signature as the default signature for new messages and replies/forwards. You may need to save your new signature first before it will show in the dropdown menu.',
    },
    {
      label: 'Save & Test',
      description: `When you're pleased, save the signature and send yourself or others some test messages to confirm it's working!`,
    },
  ];



  return (
    <body>
    <Container id="container-id">
      <div style={{display: "flex", justifyContent: "center", paddingTop: "20px"}}>
        <div style={{backgroundColor: "black", borderRadius: "7px", width: "max-content", paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px"}}>
          <img
            className={classes.centeredImage}
            src={"https://email-signature-gen.s3.amazonaws.com/main/logos/signaturegenie.png"}
            alt={"main-logo"}
          />
        </div> 
      </div>
      <div style={{paddingTop: "15px"}}>
        {pageTitle ? (
          <Typography style={{fontSize: "25px", fontWeight: 400, paddingBottom: "15px"}} variant="h2" gutterBottom className={classes.centeredText}>
            {pageTitle}{authenticated ? ' - Admin' : ''}
          </Typography>
        ) : ''}
      </div>
      <Typography
        variant="subtitle1"
        gutterBottom
        className={classes.centeredText}
      ></Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {!loadingFields & !mainLoading ? 
          <Paper id="main-paper" style={{color: `${pageTextColor}`, backgroundColor: `${podBackgroundColor}`, display: `${!mainLoading & !adminLoading & !loadingFields ? 'block' : ''}`}} className={classes.paper}>
            <form style={{display: `${!mainLoading & !adminLoading & !loadingFields ? 'block' : ''}`, marginTop: "-18px"}} className={classes.root} noValidate autoComplete="off">
              <TextField
                fullWidth={true}
                required
                label="Full Name"
                value={state.fullName}
                name={"fullName"}
                onChange={handleChange}
              />
              {titleDisabled ? '' :
              <TextField
                fullWidth={true}
                label="Title / Department"
                value={state.title}
                name={"title"}
                onChange={handleChange}
              />
              }
              {companyDisabled ? '' :
              <TextField
                fullWidth={true}
                label="Company Name"
                value={defaultCompany ? defaultCompany : state.company}
                name={"company"}
                onChange={handleChange}
                disabled={companyDisabled}
              />
              }
              {addressDisabled ? '' : 
              <TextField
                fullWidth={true}
                required
                label="Address"
                placeholder="Address"
                value={defaultAddress ? defaultAddress : state.address}
                name={"address"}
                onChange={handleChange}
                disabled={addressDisabled}
              />
              }
              {mobilePhoneDisabled ? '' :
              <TextField
                fullWidth={true}
                label="Mobile Phone"
                placeholder="585-994-7432"
                value={defaultMobile ? defaultMobile : state.mobile}
                name={"mobile"}
                onChange={handleChange}
                disabled={mobilePhoneDisabled}
              >
                <InputMask mask="999-999-9999" maskChar=" " />
              </TextField>
              }
              {officePhoneDisabled ? '' :
              <TextField
                fullWidth={true}
                required
                label="Office Phone"
                placeholder="585-378-0984"
                value={defaultPhone ? defaultPhone : state.phone}
                name={"phone"}
                onChange={handleChange}
                disabled={officePhoneDisabled}
              />
              }
              {websiteDisabled ? '' :
              <TextField
                fullWidth={true}
                required
                label="Website"
                value={defaultWebsite ? defaultWebsite : state.website}
                name={"website"}
                onChange={handleChange}
                disabled={websiteDisabled}
              />
              }
              {calendlyDisabled ? '' : 
              <TextField
                fullWidth={true}
                label="Calendly or Microsoft Bookings Link (Optional)"
                value={defaultCalendlyLink ? defaultCalendlyLink : state.calendlyLink}
                name={"calendlyLink"}
                onChange={handleChange}
                disabled={calendlyDisabled}
              />
              }
              <br />
              <div style={{paddingTop: "15px"}}>
                <Button
                  disabled={isStateChanged()}
                  onClick={clearState}
                  id="clear-btn"
                  style={{backgroundColor: `${buttonBackgroundColor}`}}
                >
                  Clear Data
                </Button>
                <Button
                  onClick={handleAdminClick}
                  id="admin-btn"
                  style={{backgroundColor: `${buttonBackgroundColor}`}}
                >
                  {adminLoading || switchLoading ? (<span className="loader"></span>) : authenticated ? 'Logout' : 'Admin Login'}
                </Button>
                {/* {authenticated ?
                 <><Avatar {...stringAvatar(`${usern ? usern : 'fuck you'}`)} /> <span>{usern ? usern : 'fuck you'}</span></>
                :
                ''
                } */}
                <div id="admin-login" style={{display: "none"}}>
                  <TextField
                    id="email-field"
                    fullWidth={true}
                    label="Email"
                    value={email}
                    name={"email-field"}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <TextField
                    fullWidth={true}
                    label="Password"
                    value={pass}
                    name={"pass-field"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(e.target.value);
                      if (e.target.value.length >= 1) {
                        setloginButtonDisabled(false)
                        if (e.key === 'Enter') {
                          setAdminLoading(true)
                          handleLoginClick()
                        }
                      } else {
                        setloginButtonDisabled(true)
                      }
                    }}
                    type="password"
                    onKeyPress={(e) => {
                      if (!loginButtonDisabled) {
                        if (e.key === 'Enter') {
                          setAdminLoading(true)
                          handleLoginClick()
                        }
                      }
                    }}
                  />
                  <br/>
                  <br/>
                  <Button
                    onClick={handleLoginClick}
                    id="login-btn"
                    disabled={loginButtonDisabled}
                    style={{backgroundColor: `${buttonBackgroundColor}`}}
                  >
                    Login
                  </Button>
                  {showError ? <p style={{color: "red"}}>{errorMessage}</p> : ''}
                </div>
              </div>
            </form>
          </Paper>
          :
          ''
          }
          {authenticated ?
          <Paper className={classes.paper} style={{marginTop: "15px", color: `${pageTextColor}`, backgroundColor: `${podBackgroundColor}`}}>
            <div style={{textAlign: "center"}}>
              <span style={{fontSize: "32px"}}>Field Visibility</span>
            </div>
            <div style={{textAlign: "left", paddingTop: "20px"}}>
              <span style={{fontSize: "18px"}}>Allow customization of these fields:</span>
            </div>

    <List
      sx={{ width: '100%', bgcolor: `${podBackgroundColor}` }}
    >
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-wifi" primary="Company" />
        <MaterialUISwitch
          checked={!companyDisabled}
          onChange={handleCompanySwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Title/Department" />
        <MaterialUISwitch
          checked={!titleDisabled}
          onChange={handleTitleSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Address" />
        <MaterialUISwitch
          checked={!addressDisabled}
          onChange={handleAddressSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Mobile Phone" />
        <MaterialUISwitch
          checked={!mobilePhoneDisabled}
          onChange={handleMobilePhoneSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Office Phone" />
        <MaterialUISwitch
          checked={!officePhoneDisabled}
          onChange={handleOfficePhoneSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Website" />
        <MaterialUISwitch
          checked={!websiteDisabled}
          onChange={handleWebsiteSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Calendly/Bookings" />
        <MaterialUISwitch
          checked={!calendlyDisabled}
          onChange={handleCalendlySwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
    </List>
            <p><i>Any fields that are disabled will also be hidden. Static fields (configurable below) will still show in the generated signature even when disabled above.</i></p>
            <Divider />
            <div style={{textAlign: "center", paddingBottom: "15px", paddingTop: "7px"}}>
              <span style={{fontSize: "32px"}}>Default/Static Fields</span>
            </div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccord('panel1')} style={{backgroundColor: `${podBackgroundColor}`}}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{color: `${pageTextColor}`}}>Company and Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className={classes.root}>
              <TextField
                fullWidth={true}
                label="Company Name"
                value={defaultCompany}
                name={"company"}
                onChange={(e) => {
                  setDefaultCompany(e.target.value)
                }}
                disabled={false}
              />
              <TextField
                fullWidth={true}
                required
                label="Address"
                placeholder="Address"
                value={defaultAddress}
                name={"address"}
                onChange={(e) => {
                  setDefaultAddress(e.target.value)
                }}
                disabled={false}
              />
            </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccord('panel2')} style={{backgroundColor: `${podBackgroundColor}`}}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography style={{color: `${pageTextColor}`}}>Phone Numbers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
              <TextField
                fullWidth={true}
                label="Mobile Phone"
                placeholder="585-994-7432"
                value={defaultMobile}
                name={"mobile"}
                onChange={(e) => {
                  setDefaultMobile(e.target.value)
                }}
                disabled={false}
              />
              <TextField
                fullWidth={true}
                required
                label="Office Phone"
                placeholder="585-987-7478"
                value={defaultPhone}
                name={"phone"}
                onChange={(e) => {
                  setDefaultPhone(e.target.value)
                }}
                disabled={false}
              />
            </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccord('panel3')} style={{backgroundColor: `${podBackgroundColor}`}}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography style={{color: `${pageTextColor}`}}>Website and meeting link</Typography>
        </AccordionSummary>
        <AccordionDetails>
           <div className={classes.root}>
              <TextField
                fullWidth={true}
                required
                label="Website"
                value={defaultWebsite}
                name={"website"}
                onChange={(e) => {
                  setDefaultWebsite(e.target.value)
                }}
                disabled={false}
              />
              <TextField
                fullWidth={true}
                label="Calendly or Microsoft Bookings Link (Optional)"
                value={defaultCalendlyLink}
                name={"calendlyLink"}
                onChange={(e) => {
                  setDefaultCalendlyLink(e.target.value)
                }}
                disabled={false}
              />
            </div>
        </AccordionDetails>
      </Accordion>
      <div style={{paddingTop: "15px"}}>
      <TextField
        fullWidth={true}
        label="Page Title"
        value={pageTitle}
        name={"pageTitle"}
        onChange={(e) => {
          setPageTitle(e.target.value)
        }}
        disabled={false}
      />
      </div>
      <div style={{paddingTop: "15px", paddingBottom: "10px"}}>
        <Button
          onClick={handleSaveClick}
          id="save-btn"
          style={{backgroundColor: `${buttonBackgroundColor}`}}
        >
          {defaultSaveLoading ? (<><span className="loader"></span><span style={{paddingLeft: "7px"}}>Saving</span></>) : defaultSaveSuccess ? (<><CheckIcon /><span style={{paddingLeft: "7px"}}>Saved</span></>) : (<><SaveIcon /><span style={{paddingLeft: "7px"}}>Save</span></>)}
        </Button>
      </div>
      <Divider />
        <div style={{textAlign: "center", paddingBottom: "15px", paddingTop: "7px"}}>
          <span style={{fontSize: "32px"}}>Customization</span>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <div>
              <center>
              {selectedIndex === 0 &&
              <>
                <HexColorPicker color={pageBackgroundColor} onChange={setPageBackgroundColor} />
                <div style={{paddingTop: "15px", width: "100%"}}>
                  <TextField
                    fullWidth={true}
                    label="HEX"
                    value={pageBackgroundColor}
                    name={"color"}
                    onChange={(e) => {
                      setPageBackgroundColor(e.target.value)
                    }}
                    disabled={false}
                  />
                </div>
              </>
              }
              {selectedIndex === 1 &&
              <>
                <HexColorPicker color={pageTextColor} onChange={setPageTextColor} />
                <div style={{paddingTop: "15px", width: "100%"}}>
                  <TextField
                    fullWidth={true}
                    label="HEX"
                    value={pageTextColor}
                    name={"color"}
                    onChange={(e) => {
                      setPageTextColor(e.target.value)
                    }}
                    disabled={false}
                  />
                </div>
              </>
              }
              {selectedIndex === 2 &&
              <>
                <HexColorPicker color={podBackgroundColor} onChange={setPodBackgroundColor} />
                <div style={{paddingTop: "15px", width: "100%"}}>
                  <TextField
                    fullWidth={true}
                    label="HEX"
                    value={podBackgroundColor}
                    name={"color"}
                    onChange={(e) => {
                      setPodBackgroundColor(e.target.value)
                    }}
                    disabled={false}
                  />
                </div>
              </>
              }
              {selectedIndex === 3 &&
              <>
                <HexColorPicker color={buttonBackgroundColor} onChange={setButtonBackgroundColor} />
                <div style={{paddingTop: "15px", width: "100%"}}>
                  <TextField
                    fullWidth={true}
                    label="HEX"
                    value={buttonBackgroundColor}
                    name={"color"}
                    onChange={(e) => {
                      setButtonBackgroundColor(e.target.value)
                    }}
                    disabled={false}
                  />
                </div>
              </>
              }
              </center>
              <div style={{paddingTop: "15px", width: "100%"}}>
                <div style={{display: "inline-flex"}}>
                <Button
                  onClick={handleColorSaveClick}
                  id="save-btn"
                  style={{backgroundColor: `${buttonBackgroundColor}`}}
                >
                  {brandingSaveLoading ? (<><span className="loader"></span><span style={{paddingLeft: "7px"}}>Saving</span></>) : brandingSaveSuccess ? (<><CheckIcon /><span style={{paddingLeft: "7px"}}>Saved</span></>) : (<><SaveIcon /><span style={{paddingLeft: "7px"}}>Save</span></>)}
                </Button>
                <Button
                  onClick={() => {
                    setPageBackgroundColor('#000000')
                    setPageTextColor('#757575')
                    setPodBackgroundColor('#ffffff')
                    setButtonBackgroundColor('#000000')
                  }}
                  id="save-btn"
                  disabled={false}
                  style={{backgroundColor: `${buttonBackgroundColor}`}}
                >
                  Default
                </Button>
                </div>
              </div>
              
            </div>
          </Grid>
          <Grid item xs={7}>
        <center>
      <List component="nav" aria-label="">
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemText primary="Page Background" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemText primary="Pod Text" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemText primary="Pod Background" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemText primary="Button Background" />
        </ListItemButton>
      </List>
      </center>
      </Grid>
      </Grid>

          </Paper>
          :
          ''
          }
        </Grid>
        <Grid item xs={6}>

          <Paper className={classes.paper}>{showSignature()}</Paper>

          {authenticated ?
          <Paper className={classes.paper} style={{marginTop: "15px", color: `${pageTextColor}`, backgroundColor: `${podBackgroundColor}`}}>
            <div style={{textAlign: "center"}}>
              <span style={{fontSize: "62px", color: `${pageTextColor}`}}><b>{totalNumCopies === 0 ? '' : totalNumCopies}</b></span>
              <br/>
              <span style={{fontSize: "32px"}}>Total Clipboard Copies</span>
              <br/>
              {/* <span>Last: {responseDB.at(-1).name} - {responseDB.at(-1).company}</span> */}
            </div>
          </Paper>
          :
            ''
          }
          {authenticated ?
          <Paper className={classes.paper} style={{marginTop: "15px", color: `${pageTextColor}`, backgroundColor: `${podBackgroundColor}`}}>
            {adminLoading ? '' : 
            <>
              <Table
                logo={imgUrl}
                fullName={state.fullName}
                company={state.company}
                title={state.title}
                address={state.address}
                phone={state.phone}
                mobile={state.mobile}
                website={state.website}
                calendlyLink={state.calendlyLink}
                textmask={state.textmask}
                totalCopies={totalNumCopies}
                inputData={responseDB}
              />

              <div style={{paddingTop: "10px"}}>
                {/* <Divider /> */}
              </div>
              
              <UploadAndDisplayImage
                defaultImgUrl={imgUrl}
                setImgUrl={handleSetImgUrl}
                buttonBackgroundColor={buttonBackgroundColor}
                logo={imgUrl}
                fullName={state.fullName}
                company={defaultCompany !== '' ? defaultCompany : state.company}
                title={state.title}
                address={defaultAddress !== '' ? defaultAddress : state.address}
                phone={defaultPhone !== '' ? defaultPhone : state.phone}
                mobile={state.mobile}
                website={defaultWebsite !== '' ? defaultWebsite : state.website}
                calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
                textmask={state.textmask}
                totalCopies={totalNumCopies}
                facebookURL={facebookURL ? facebookURL : "https://facebook.com"}
                googleURL={googleURL ? googleURL : "https://google.com"}
                linkedInURL={linkedInURL ? linkedInURL : "https://linkedin.com"}
              />

                <div style={{paddingTop: "15px"}}>
                  <Divider />
                </div>
                <div style={{textAlign: "center", paddingTop: "15px", paddingBottom: "25px"}}>
                  <span style={{fontSize: "32px"}}>Social Links</span>
                  <br/>
                  <br/>
                  <span>Please be sure that your links use "<b>https://</b>" and <u>not</u> "<b>http://</b>" and the links are short and concise like the examples otherwise your mail could be blocked.</span>
                </div>

                <div style={{paddingBottom: "10px"}}>
                  <TextField
                    fullWidth={true}
                    label="Facebook URL"
                    value={facebookURL}
                    name={"pageTitle"}
                    onChange={(e) => {
                      setFacebookURL(e.target.value)
                    }}
                    disabled={false}
                  />
                </div>
                <div style={{paddingBottom: "10px"}}>
                  <TextField
                    fullWidth={true}
                    label="Google URL"
                    value={googleURL}
                    name={"pageTitle"}
                    onChange={(e) => {
                      setGoogleURL(e.target.value)
                    }}
                    disabled={false}
                  />
                </div>
                <div style={{paddingBottom: "10px"}}>
                  <TextField
                    fullWidth={true}
                    label="LinkedIn URL"
                    value={linkedInURL}
                    name={"pageTitle"}
                    onChange={(e) => {
                      setLinkedInURL(e.target.value)
                    }}
                    disabled={false}
                  />
                </div>
                <div style={{paddingTop: "15px"}}>
                  <Button
                    onClick={handleSocialSaveClick}
                    id="save-btn"
                    style={{backgroundColor: `${buttonBackgroundColor}`}}
                  >
                    {socialSavingLoading ? (<><span className="loader"></span><span style={{paddingLeft: "7px"}}>Saving</span></>) : socialSavingSuccess ? (<><CheckIcon /><span style={{paddingLeft: "7px"}}>Saved</span></>) : (<><SaveIcon /><span style={{paddingLeft: "7px"}}>Save</span></>)}
                  </Button>
                </div>

              </>
            
            }
          </Paper>
          :
          ''
          }
        </Grid>
      </Grid>
      {/* <center>
        <span style={{fontSize: "12px", color: `${pageTextColor} !important`, paddingTop: "15px"}}><a href="https://smorin.app" target="_blank">smorin.app</a></span>
      </center> */}
    </Container>
    </body>
  );
}

export default App;
