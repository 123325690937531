// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactTimeAgo from 'react-time-ago'
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_SortingState,
  type MRT_Virtualizer,
  type MRT_Row,
} from 'material-react-table';
import {
    Box,
    Button,
  } from "@material-ui/core";
// import { makeData, type Person } from './makeData';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Signature from "./Signature";
import { PhotoSignatureProps } from "./App";

type Person = {
  name: string;
  company: string;
  title: string;
  $createdAt: Date;
};

const Table = ({inputData}, props: PhotoSignatureProps) => {
  const columns = useMemo<MRT_ColumnDef<Person>[]>(
    //column definitions...
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 170,
      },
      {
        accessorKey: 'company',
        header: 'Company',
        size: 170,
      },
      {
        accessorKey: 'title',
        header: 'Title',
        size: 170,
      },
      {
        accessorKey: 'copyType',
        header: 'Copy Type',
        size: 140,
      },
      {
        accessorKey: 'clientIP',
        header: 'Client IP',
        size: 140,
      },
    //   {
    //     accessorKey: '$createdAt',
    //     header: 'Submitted',
    //     size: 170,
    //   },
    //   {
    //     accessorKey: '$id',
    //     header: 'ID',
    //     size: 170,
    //   },
    ],
    [],
    //end
  );

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  
  const csvExporter = new ExportToCsv(csvOptions);

  //optionally access the underlying virtualizer instance
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [data, setData] = useState(inputData);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  useEffect(() => {
    setData(inputData);
  }, [inputData]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  const handleExportRows = (rows: MRT_Row<Person>[]) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    console.log(data.map((idata: { name: string; }) => idata.name));
    let tempData = data.map((idata: { name: string; }) => (
        idata.name + ','
    ))
    console.log(tempData)
    csvExporter.generateCsv(tempData);
  };

  return (
    <div>
    <MaterialReactTable
      columns={columns}
      data={data.toReversed()} //10,000 rows
      defaultDisplayColumn={{ enableResizing: true }}
      enableBottomToolbar={false}
      enableColumnResizing
      enableColumnOrdering
      enableColumnVirtualization
      enableGlobalFilterModes
      enablePagination={false}
      enablePinning
    //   enableRowNumbers
      enableRowVirtualization
      muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
      onSortingChange={setSorting}
      state={{ isLoading, sorting }}
    //   rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
    //   rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
    //   columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer
      sortDescFirst={true}
      initialState={{ showColumnFilters: true, density: 'compact' }}
      enableSorting
      positionToolbarAlertBanner="bottom"
    //   renderTopToolbarCustomActions={({ table }) => (
    //     <Box
    //       sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
    //     >
    //       <Button
    //         color="primary"
    //         onClick={handleExportData}
    //         startIcon={<FileDownloadIcon />}
    //         variant="contained"
    //       />
    //     </Box>
    //   )}
      renderTopToolbarCustomActions={({ table }) => {
        return (
            <span style={{fontSize: "26px", color: "#757575"}}>History</span>
        )
      }}
      renderDetailPanel={({ row }) => (
        <>
            <div>
                <div style={{textAlign: "left", paddingBottom: "12px"}}>
                    <span style={{fontSize: "16px"}}>Submitted <ReactTimeAgo date={new Date(row.original.$createdAt)} locale="en-US"/></span>
                    <br/>
                    <span>{new Date(row.original.$createdAt).toLocaleString()}</span>
                </div>
                {/* <div style={{paddingBottom: "8px"}}>
                    <Button 
                        onClick={() => {document.getElementById('preview')!.style.display === "inline" ? document.getElementById('preview')!.style.display = "none" : document.getElementById('preview')!.style.display = "inline"}}
                        style={{height: "30px"}}

                    >
                        Preview
                    </Button>
                </div> */}
            </div>
            {/* <div style={{display: "none"}} id="preview">
                <div>
                    <React.Fragment>
                        <Signature
                            logo={row.original.company === 'LMC Industrial Contractors, Inc.' ? 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' : 'https://email-signature-gen.s3.amazonaws.com/main/logos/amp-logo.jpg'}
                            fullName={row.original.name}
                            company={row.original.company}
                            title={row.original.title}
                            address={'9431 Foster Wheeler Road, Dansville, NY 14481'}
                            phone={'585-331-3131'}
                            mobile={'<NOT STORED>*'}
                            website={row.original.company === 'LMC Industrial Contractors, Inc.' ? 'www.lmcic.com' : 'www.americanmotivepower.com'}
                            calendlyLink={''}
                            textmask={props.textmask}
                            totalCopies={props.totalCopies}
                        />
                    </React.Fragment>
                </div>
                <div>
                    <p><i>*Mobile phone numbers are not stored or logged.</i></p>
                </div>
            </div> */}
        </>
      )}
      // muiTableHeadCellProps={{
      //   //simple styling with the `sx` prop, works just like a style prop in this example
      //   sx: {
      //     color: 'pink'
      //   },
      // }}
      // muiTableBodyProps={{
      //   sx: {
      //     color: 'pink'
      //   }
      // }}
    />
    </div>
  );
};

//virtualizerInstanceRef was renamed to rowVirtualizerInstanceRef in v1.5.0
//virtualizerProps was renamed to rowVirtualizerProps in v1.5.0

export default Table;
