import React, { useState, useRef } from "react";
import {
  Box,
    Button, Slider, TextField,
  } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
  } from 'react-image-crop'
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import 'react-image-crop/dist/ReactCrop.css'
import SignaturePreview from "./SignaturePreview";
import SaveIcon from '@mui/icons-material/Save';

import { Client, Databases, Account, ID, Query, Storage } from 'appwrite';
import CheckIcon from '@mui/icons-material/Check';
import Signature from "./Signature";
import { Divider, Grid } from "@mui/material";


const client = new Client();
const account = new Account(client);
const databases = new Databases(client);

const esg_project_id = "64f3b89f06b3e6a9d37f"

const storage = new Storage(client);
client
    .setEndpoint('https://sm-b1.smorin.app/v1') // Your API Endpoint
    .setProject(`${esg_project_id}`) // Your project ID
;
    // const promise = account.createAnonymousSession();

    // promise.then(function (response) {
    //     console.log(response); // Success
    // }, function (error) {
    //     console.log(error); // Failure
    // });

const esg_db1 = "64f3b8e51831e63a90d9"
const esg_c1 = "64f3b8f27e7a6bfdfce6"

const esg_field_vis = "64f3bb87151160e5737b"
const esg_field_vis_doc = "64f3be2171359f374dda"

const esg_defaults_collect = "64f3b98a98421a486f11"
const esg_defaults_doc = "64f3bd327b14ee475661"

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }


const UploadAndDisplayImage = ({buttonBackgroundColor, logo, fullName, company, title, address, phone, mobile, website, calendlyLink, textmask, totalNumCopies, setImgUrl, defaultImgUrl, facebookURL, googleURL, linkedInURL}) => {

  const [selectedImage, setSelectedImage] = useState(null);

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1.78 / 1)
  const [imgName, setImgName] = useState('')
  const [imgSaveLoading, setImgSaveLoading] = useState(false);
  const [imgSaveSuccess, setImgSaveSuccess] = useState(false);

  function fileNameSafe(str) {
    return str.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  }

  const handleSaveClick = () => {
      setImgSaveLoading(true)
      if (!previewCanvasRef.current) {
        throw new Error('Crop canvas does not exist')
      }

      previewCanvasRef.current.toBlob((blob) => {
        if (!blob) {
          throw new Error('Failed to create blob')
        }

        const file = new File([blob], `${fileNameSafe(imgName)}${Math.random().toString(36).substring(2, 36)}.jpg`);

        const promises = storage.createFile('64f476c5a97bd277158d', `esg-${fileNameSafe(imgName)}-${Math.random().toString(36).substring(2, 36)}`, file);
        promises.then(function (response) {
            // console.log(response); // Success
            const imgId = response.$id
            setImgUrl(`https://sm-b1.smorin.app/v1/storage/buckets/64f476c5a97bd277158d/files/${response.$id}/view?project=64f3b89f06b3e6a9d37f&mode=admin`)
            const promise = databases.updateDocument(`${esg_db1}`, `${esg_defaults_collect}`, `${esg_defaults_doc}`, {
              defaultImgUrl: `https://sm-b1.smorin.app/v1/storage/buckets/64f476c5a97bd277158d/files/${imgId}/view?project=64f3b89f06b3e6a9d37f&mode=admin`
            });
        
            promise.then(function (response) {
                // console.log(response); // Success
                setImgSaveSuccess(true)
                setImgSaveLoading(false)
                setTimeout(() => setImgSaveSuccess(false), 5000)
              }, function (error) {
                console.log(error); // Failure
                setImgSaveSuccess(false)
                setImgSaveLoading(false)
            });
            // setTimeout(() => setImgSrc(null), 7000)
        }, function (error) {
            console.log(error); // Failure
            setImgSaveSuccess(false)
            setImgSaveLoading(false)
            // setTimeout(() => setImgSrc(null), 5000)
        });

      }) 

    }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      blobUrlRef.current = URL.createObjectURL(blob)
      hiddenAnchorRef.current!.href = blobUrlRef.current
      hiddenAnchorRef.current!.click()
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else {
      setAspect(16 / 9)

      if (imgRef.current) {
        const { width, height } = imgRef.current
        const newCrop = centerAspectCrop(width, height, 16 / 9)
        setCrop(newCrop)
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height))
      }
    }
  }

  const formatRotateLabel = (value) => {
    return `${value}°`; // Customize this format as needed
  };

  const formatScaleLabel = (value) => {
    return `${value}x`; // Customize this format as needed
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <div>
        <div style={{textAlign: "center", paddingBottom: "15px", paddingTop: "15px"}}>
          <span style={{fontSize: "32px"}}>Company Logo</span>
        </div>
        {imgSrc ? (
        <>
          <>
            <div className="App">
                {/* <div>
                  <button onClick={handleToggleAspectClick}>
                    Toggle aspect {aspect ? 'off' : 'on'}
                  </button>
                </div> */}
              {!!imgSrc && (
                <>
                <div style={{justifyContent: "center", display: "flex"}}>
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  // minWidth={400}
                  minHeight={200}
                  style={{borderRadius: "7px"}}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    // width={"120"}
                    // height={"70"}
                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, borderRadius: "7px" }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
                </div>
                
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div style={{textAlign: "center", paddingBottom: "0px", paddingTop: "10px"}}>
                      <span style={{fontSize: "20px"}}>Scale</span>
                    </div>
                    <Box sx={{ width: "100%" }}>
                      <Slider
                        min={0.1}
                        max={4}
                        step={0.001}
                        value={scale}
                        onChange={(e, value) => setScale(Number(value))}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        valueLabelFormat={formatScaleLabel}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <div style={{textAlign: "center", paddingBottom: "0px", paddingTop: "10px"}}>
                      <span style={{fontSize: "20px"}}>Rotate</span>
                    </div>
                    <Box sx={{ width: "100%" }}>
                      <Slider
                        min={-180}
                        max={180}
                        value={rotate}
                        onChange={(e, value) =>
                          setRotate(Math.min(180, Math.max(-180, Number(value))))
                        }
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        valueLabelFormat={formatRotateLabel}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
              )}
              {!!completedCrop && (
                <>
                  <div style={{paddingTop: "10px", paddingBottom: "15px"}}>
                    <div style={{borderRadius: "7px", backgroundColor: "white", overflow: "hidden"}}>
                      <Grid container spacing={2} style={{overflow: "hidden"}}>
                        <Grid item xs={6} style={{overflow: "hidden"}}>
                          <div style={{textAlign: "center", paddingBottom: "7px", paddingTop: "7px"}}>
                            <span style={{fontSize: "28px"}}>Preview</span>
                          </div>
                          <SignaturePreview
                            logoPreview={previewCanvasRef}
                            logo={imgSrc}
                            fullName={fullName ? fullName : "Bill Billington"}
                            company={company}
                            title={title ? title : "Billing"}
                            address={address}
                            phone={phone}
                            mobile={mobile ? mobile : "555-444-3322"}
                            website={website}
                            calendlyLink={calendlyLink}
                            textmask={textmask}
                            totalCopies={totalNumCopies}
                            facebookURL={facebookURL ? facebookURL : "https://facebook.com"}
                            googleURL={googleURL ? googleURL : "https://google.com"}
                            linkedInURL={linkedInURL ? linkedInURL : "https://linkedin.com"}
                          />
                        </Grid>

                        <Grid item xs={6} style={{overflow: "hidden"}}>
                          <div style={{textAlign: "center", paddingBottom: "7px", paddingTop: "7px"}}>
                            <span style={{fontSize: "28px"}}>Current</span>
                          </div>
                          <Signature
                            logo={logo}
                            fullName={fullName ? fullName : "Bill Billington"}
                            company={company}
                            title={title ? title : "Billing"}
                            address={address}
                            phone={phone}
                            mobile={mobile ? mobile : "555-444-3322"}
                            website={website}
                            calendlyLink={calendlyLink}
                            textmask={textmask}
                            totalCopies={totalNumCopies}
                            facebookURL={facebookURL ? facebookURL : "https://facebook.com"}
                            googleURL={googleURL ? googleURL : "https://google.com"}
                            linkedInURL={linkedInURL ? linkedInURL : "https://linkedin.com"}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  
                  <div>
                    <a
                      href="#hidden"
                      ref={hiddenAnchorRef}
                      download
                      style={{
                        position: 'absolute',
                        top: '-200vh',
                        visibility: 'hidden',
                      }}
                    >
                      Hidden download
                    </a>
                  </div>
                </>
              )}
            </div>
          </>
            {/* <div style={{paddingBottom: "10px"}}>
              <TextField
                id="img-name-field"
                fullWidth={true}
                label="Image Name"
                value={imgName}
                name={"img-name-field"}
                onChange={(e) => {
                  setImgName(e.target.value);
                }}
              />
            </div> */}
            <Button
              onClick={handleSaveClick}
              style={{backgroundColor: `${buttonBackgroundColor}`}}
            >
                {imgSaveLoading ? (<><span className="loader"></span><span style={{paddingLeft: "7px"}}>Saving</span></>) : imgSaveSuccess ? (<><CheckIcon /><span style={{paddingLeft: "7px"}}>Saved!</span></>) : (<><SaveIcon /><span style={{paddingLeft: "7px"}}>Save</span></>)}
            </Button>
            <Button
              onClick={() => setImgSrc(null)}
              style={{backgroundColor: `${buttonBackgroundColor}`}}
            >
                <><DeleteForeverIcon /><span style={{paddingLeft: "7px"}}>Remove</span></>
            </Button>
            <Button
              onClick={onDownloadCropClick}
              style={{backgroundColor: `${buttonBackgroundColor}`}}
            >
              <><CloudDownloadIcon /><span style={{paddingLeft: "7px"}}>Download Cropped</span></>
            </Button>
          </>
        ) : (
          <>
          <div style={{justifyContent: "center", display: "flex", paddingBottom: "15px"}}>
            {console.log(defaultImgUrl)}
            <img
              alt="Image not found! Upload one."
              src={defaultImgUrl ? defaultImgUrl : 'https://email-signature-gen.s3.amazonaws.com/main/logos/image-not-found.png'}
              width={240}
              height={140}
            />
          </div>
          <Button 
            component="label" 
            variant="contained" 
            startIcon={<CloudUploadIcon />}
            style={{backgroundColor: `${buttonBackgroundColor}`}}
          >
            Upload Image
            <VisuallyHiddenInput 
              type="file"
              accept="image/*"
              name="myImage"
              onChange={onSelectFile}
            />
          </Button>
          </>
        )}
    </div>
  );
};

export default UploadAndDisplayImage;